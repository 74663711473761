import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import { Button, CircularProgress, Theme, Typography } from '@mui/material';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { IStakedToken } from '../hooks/useStaking';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    gap: '0.5rem',
    flexDirection: 'column',
    marginTop: '1rem',
    paddingTop: '0.5rem',
    borderRadius: '6px',
    alignItems: 'flex-end',
    position: 'absolute',
    right: '30px',
    bottom: '-25px',
  },
  button: {
    padding: '0.75rem 1.25rem !important',
    width: '200px !important',
    transition: 'all 0.2s linear !important',
    cursor: 'pointer',
    borderRadius: '4px !important',
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: 'white !important',
    border: `1px solid ${theme.palette.primary.main} !important`,

    '&:disabled': {
      cursor: 'default',
      color: '#737373 !important',
      backgroundColor: '#171717 !important',
      border: '1px solid #262626 !important',
    },
  },
}));

interface IUnstakeButton {
  isPendingUnStaking: boolean;
  selectedForUnStaking: IStakedToken[];
  unStakeTokens: () => any;
}

const UnstakeButton: FunctionComponent<IUnstakeButton> = (props) => {
  const classes = useStyles();

  const { isPendingUnStaking, selectedForUnStaking, unStakeTokens } = props;
  const disabled = selectedForUnStaking.length === 0 || isPendingUnStaking;

  return (
    <div className={classes.root}>
      <Button
        className={classes.button}
        disabled={disabled}
        onClick={unStakeTokens}>
        <Typography variant={'h6'} style={{ marginLeft: '0.75rem' }}>
          {isPendingUnStaking ? 'Un-staking' : 'Un-stake'}
        </Typography>
        {isPendingUnStaking ? (
          <CircularProgress size={18} style={{ marginLeft: '0.75rem' }} />
        ) : (
          <LockOpenIcon
            style={{
              marginLeft: '0.75rem',
              opacity: 0.8,
              width: 20,
              height: 20,
            }}
          />
        )}
      </Button>
    </div>
  );
};

export default UnstakeButton;

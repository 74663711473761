import React, { FunctionComponent, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import { Fade, Theme, Typography } from '@mui/material';
import useFadeIn from '../hooks/useFadeIn';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: '100vh',
    width: '100%',
    backgroundColor: '#111010',
  },
  container: {
    width: '100%',
    maxWidth: '1200px',
    margin: '0 auto',
    padding: '7rem 2rem 9rem 2rem',
    position: 'relative',

    [theme.breakpoints.down('lg')]: {
      padding: '1rem 6rem 7rem 6rem',
    },

    [theme.breakpoints.down('sm')]: {
      padding: '1rem 2rem 4rem 3rem',
    },
  },
  title: {
    position: 'absolute',
    color: '#424242 !important',
    right: '100px',
    top: '250px',
    fontSize: '5rem !important',
    textAlign: 'center',

    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  questions: {
    marginTop: '3rem',
  },
  question: {
    position: 'relative',
    maxWidth: '700px',
    padding: '2rem 0',
  },
  list: {
    position: 'relative',
    listStyle: 'none',
    paddingLeft: '20px',
    color: '#57606f !important',

    '& span': {
      position: 'absolute',
      color: '#57606f',
      left: 0,
      opacity: 0.6,

      [theme.breakpoints.down('sm')]: {
        left: -5,
      },
    },

    [theme.breakpoints.down('sm')]: {
      paddingLeft: '15px',
    },

    '& li': {
      marginBottom: '10px',

      [theme.breakpoints.down('sm')]: {
        fontSize: '14px !important',
      },
    },
  },
  questionTitle: {
    color: 'white !important',

    [theme.breakpoints.down('lg')]: {
      fontSize: '2rem !important',
    },
  },
  illustration: {
    height: '100px',
    width: '2px',
    backgroundColor: '#d63031',
    position: 'absolute',
    left: -25,
    top: 38,
  },
}));

interface FaqProps {}

const Faq: FunctionComponent<FaqProps> = (props) => {
  const classes = useStyles();

  const containerRef = useRef<HTMLDivElement>(null);
  const [fadeIn] = useFadeIn(containerRef, 200);

  return (
    <div className={classes.root} ref={containerRef}>
      <div className={classes.container}>
        <Fade
          in={fadeIn}
          timeout={{
            enter: 1500,
          }}>
          <Typography variant={'h1'} className={classes.title}>
            F
            <br />
            A
            <br />Q
          </Typography>
        </Fade>
        <Fade
          in={fadeIn}
          timeout={{
            enter: 2500,
          }}>
          <div className={classes.questions}>
            <div className={classes.question}>
              <div className={classes.illustration} />
              <Typography variant={'h3'} className={classes.questionTitle}>
                What is the collection size?
              </Typography>
              <ul className={classes.list}>
                <li>
                  <span>{'//'}</span>TTOO consists of 2000 NFT’s
                </li>
                <li>
                  <span>{'//'}</span>300 Capos
                </li>
                <li>
                  <span>{'//'}</span>1700 Soldiers
                </li>
              </ul>
            </div>
            <div className={classes.question}>
              <div className={classes.illustration} />
              <Typography variant={'h3'} className={classes.questionTitle}>
                What is the mint cost?
              </Typography>
              <ul className={classes.list}>
                <li>
                  <span>{'//'}</span>$250 USDC
                </li>
                <li>
                  <span>{'//'}</span>USDC on the Ethereum Network
                </li>
              </ul>
            </div>
            <div className={classes.question}>
              <div className={classes.illustration} />
              <Typography variant={'h3'} className={classes.questionTitle}>
                Will there be a public sale?
              </Typography>
              <ul className={classes.list}>
                <li>
                  <span>{'//'}</span>
                  The sale is entirely white-listed, and spots to join the sale
                  will be earned or given out by specific criteria. Any unminted
                  supply will go to public sale after the whitelist mint time
                  window.
                </li>
              </ul>
            </div>
            <div className={classes.question}>
              <div className={classes.illustration} />
              <Typography variant={'h3'} className={classes.questionTitle}>
                What are the royalties on secondary marketplaces?
              </Typography>
              <ul className={classes.list}>
                <li>
                  <span>{'//'}</span>
                  8.5%
                </li>
              </ul>
            </div>
            <div className={classes.question}>
              <div className={classes.illustration} />
              <Typography variant={'h3'} className={classes.questionTitle}>
                What is the utility/what are the benefits of holding a TTOO NFT?
              </Typography>
              <ul className={classes.list}>
                <li>
                  <span>{'//'}</span>
                  Alpha channels lead by experienced web2&3 individuals, crypto
                  gaming reports, video drops and exclusive AMA’s with industry
                  leaders
                </li>
                <li>
                  <span>{'//'}</span>Ability to vote on treasury decisions, as
                  the treasury will be backed by NFT Gaming Assets.
                </li>
                <li>
                  <span>{'//'}</span>Community gaming tournaments via Socials
                </li>
                <li>
                  <span>{'//'}</span>Discounts on NFT analytic tools
                </li>
                <li>
                  <span>{'//'}</span>Access to a crypto gaming coin trading
                  algorithm
                </li>
                <li>
                  <span>{'//'}</span>Exclusive IRL events
                </li>
                <li>
                  <span>{'//'}</span>Community networking
                </li>
                <li>
                  <span>{'//'}</span>
                  Behind the scenes insight to how Hustle moves in the markets
                  and exposure to future Hustlepedia collections and games
                </li>
              </ul>
            </div>
            <div className={classes.question}>
              <div className={classes.illustration} />
              <Typography variant={'h3'} className={classes.questionTitle}>
                What is so special about this NFT collection?
              </Typography>
              <ul className={classes.list}>
                <li>
                  <span>{'//'}</span>
                  We’ve spent the last 7 months forging a tight knit group of
                  web3 gaming enthusiasts. Through bull and bear, which our
                  CAPO’s (OG’s) undoubtedly can confess to. We wanted to make
                  sure the community truly was at the center of this project.
                </li>
                <li>
                  <span>{'//'}</span>
                  Through comprehensive and deep participation in the space,
                  Jonny and the crew have been able to get connected with a
                  great number of cutting edge projects as well as high-networth
                  individuals. As an ongoing process we will work towards
                  leveraging these connections to the benefit of our TTOO
                  holders.
                </li>
                <li>
                  <span>{'//'}</span>
                  This collection clears the clutter of influencers promoting
                  projects, and gives a full look at the moves Hustle is making
                  in the markets the second he makes them, with no cloud in the
                  sky on “when did they buy” “am I going to get dumped on” and
                  the numerous fears when buying into a coin or project after an
                  influencer. The treasury will serve as proof of concept
                  alongside the signals and portfolio allocations shared by
                  Hustle for holders exclusively.
                </li>
              </ul>
            </div>
            <div className={classes.question}>
              <div className={classes.illustration} />
              <Typography variant={'h3'} className={classes.questionTitle}>
                Partners and Collaborations
              </Typography>
              <ul className={classes.list}>
                <li>
                  <span>{'//'}</span>We are continuously adding high quality
                  partnerships and collaborations that we know will be of great
                  value to the holders. Below are a few of the locked in deals.
                  This is just the beginning...
                </li>
              </ul>
            </div>
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default Faq;

import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import { Button, CircularProgress, Theme, Typography } from '@mui/material';
import { IToken } from './TokenCard';
import LockClockIcon from '@mui/icons-material/LockClock';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    gap: '0.5rem',
    flexDirection: 'column',
    marginTop: '1rem',
    paddingTop: '0.5rem',
    borderRadius: '6px',
    alignItems: 'flex-end',
    position: 'absolute',
    right: '30px',
    bottom: '-25px',
  },
  button: {
    padding: '0.75rem 1.25rem !important',
    width: '200px !important',
    transition: 'all 0.2s linear !important',
    cursor: 'pointer',
    borderRadius: '4px !important',
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: 'white !important',
    border: `1px solid ${theme.palette.primary.main} !important`,

    '&:disabled': {
      cursor: 'default',
      color: '#737373 !important',
      backgroundColor: '#171717 !important',
      border: '1px solid #262626 !important',
    },
  },
}));

interface IStakingButton {
  selectedForStaking: IToken[];
  stakeTokens: () => any;
  isPendingStaking: boolean;
  isApproving: boolean;
}

const StakingButton: FunctionComponent<IStakingButton> = (props) => {
  const classes = useStyles();
  const { selectedForStaking, stakeTokens, isPendingStaking, isApproving } =
    props;

  const loading = isApproving || isPendingStaking;
  const disabled = selectedForStaking.length === 0 || loading;

  return (
    <div className={classes.root}>
      <Button
        className={classes.button}
        disabled={disabled}
        onClick={stakeTokens}>
        <Typography
          variant={'h6'}
          style={{ marginLeft: '0.75rem', marginRight: '0.75rem' }}>
          {isApproving ? 'Approving' : isPendingStaking ? 'Staking' : 'Stake'}
        </Typography>
        {loading ? (
          <CircularProgress size={18} style={{ marginLeft: '0.75rem' }} />
        ) : (
          <LockClockIcon
            style={{
              opacity: 0.8,
              width: 20,
              height: 20,
            }}
          />
        )}
      </Button>
    </div>
  );
};

export default StakingButton;

import { useEffect, useState } from 'react';

declare let window: any;

export const useConnectAccount = () => {
  const [currentAccount, setCurrentAccount] = useState<string | null>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!currentAccount) getAndSetAccount();

    const accountWasChanged = (accounts: string[]) => {
      setLoading(true);

      if (accounts.length === 0) {
        return setCurrentAccount(null);
      }

      setCurrentAccount(accounts[0]);
      console.log('Account was changed', accounts[0]);
    };

    const clearAccount = () => {
      setCurrentAccount(null);
      console.log('Account was cleared');
    };

    window.ethereum.on('disconnect', clearAccount);
    window.ethereum.on('connect', getAndSetAccount);
    window.ethereum.on('accountsChanged', accountWasChanged);

    return () => {
      window.ethereum.removeListener('disconnect', clearAccount);
      window.ethereum.removeListener('connect', getAndSetAccount);
      window.ethereum.removeListener('accountsChanged', accountWasChanged);
    };
  }, []);

  async function getAndSetAccount() {
    const changedAccounts = await window.ethereum.send(
      'eth_requestAccounts',
      [],
    );

    const account = changedAccounts.result[0];
    setCurrentAccount(account);
    setLoading(false);
    console.log('Account was set', account);
  }

  return { currentAccount, getAndSetAccount, loading, setLoading };
};

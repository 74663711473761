import React, { FunctionComponent, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import { CircularProgress, Fade, Theme, Typography } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100vh',
    width: '100vw',
    backgroundColor: 'rgba(0, 0, 0, 0.9)',
    position: 'fixed',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10,
  },
  dialog: {
    height: '450px',
    width: '600px',
    borderRadius: '8px',
    backgroundColor: '#212121',
    padding: '1rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',

    [theme.breakpoints.down('sm')]: {
      width: '300px',
    },
  },
  info: {
    marginTop: '4rem',
    textAlign: 'center',
    maxWidth: '300px',

    '& h5': {
      fontSize: '1.3rem !important',
    },
  },
  icon: {
    height: '100px !important',
    width: '100px !important',
  },
}));

export enum DialogStatus {
  Approving = 'approving',
  Minting = 'minting',
  Minted = 'minted',
  MintError = 'mintError',
  None = 'none',
}

interface MintDialogProps {
  status: DialogStatus;
  close: () => void;
  totalPrice: number;
  amountToMint: number;
}

const MintDialog: FunctionComponent<MintDialogProps> = (props) => {
  const { status, close, totalPrice, amountToMint } = props;
  const classes = useStyles();

  const bgRef = useRef<HTMLDivElement>(null);
  const mintText = `${amountToMint} TTOO NFT${amountToMint > 1 ? 's' : ''}`;

  function setText() {
    if (status === DialogStatus.Approving) {
      return `Approving USDC to spend $${totalPrice} for ${amountToMint} TTOO tokens...`;
    }

    if (status === DialogStatus.Minting) {
      return `Minting ${mintText}...`;
    }

    if (status === DialogStatus.Minted) {
      return 'Successfully minted! Token(s) will appear in your wallet shortly!';
    }

    if (status === DialogStatus.MintError) {
      return 'An unexpected error occurred while minting';
    }

    return '';
  }

  function setIcon() {
    if (status === DialogStatus.MintError) {
      return <ErrorOutlineIcon color={'primary'} className={classes.icon} />;
    }

    if (status === DialogStatus.Minted) {
      return (
        <CheckCircleOutlineIcon color={'secondary'} className={classes.icon} />
      );
    }

    if (status === DialogStatus.None) {
      return null;
    }

    return <CircularProgress size={80} color={'primary'} />;
  }

  function handleClick(e: any) {
    if (status === DialogStatus.MintError || status === DialogStatus.Minted) {
      if (e.target === bgRef.current) close();
    }
  }

  return (
    <Fade in={status !== DialogStatus.None} timeout={700}>
      <div ref={bgRef} className={classes.root} onClick={(e) => handleClick(e)}>
        <div className={classes.dialog}>
          {setIcon()}
          <div className={classes.info}>
            <Typography variant={'h5'}>{setText()}</Typography>
          </div>
        </div>
      </div>
    </Fade>
  );
};

export default MintDialog;

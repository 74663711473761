import React, { FunctionComponent, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import { Fade, Theme } from '@mui/material';
import { cdnRoot } from '../theme';
import useFadeIn from '../hooks/useFadeIn';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: '600px',
    width: '100%',
    backgroundColor: '#111010',
  },
  container: {
    width: '100%',
    maxWidth: '1200px',
    margin: '0 auto',
    padding: '0rem 0 15rem 0',
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'center',
    justifyContent: 'center',

    [theme.breakpoints.down('lg')]: {
      padding: '1rem 6rem 7rem 6rem',
    },

    [theme.breakpoints.down('sm')]: {
      padding: '1rem 3rem 7rem 3rem',
    },
  },
  partner: {
    margin: '60px 30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
      margin: '20px auto',

      '& img': {
        width: '100% !important',
      },
    },
  },
}));

interface PartnersProps {}

const PARTNERS: string[] = [
  'shrapnel',
  'collider',
  'mph',
  'clementines_nightmare',
  'pixelated_ink',
  'world_overlay',
  'steady_stack',
  'phantasma',
  'wagmi',
  'meta_nanos',
  'opus_labs',
];

const Partners: FunctionComponent<PartnersProps> = (props) => {
  const classes = useStyles();

  const containerRef = useRef<HTMLDivElement>(null);
  const [fadeIn] = useFadeIn(containerRef, 0);

  function renderPartners() {
    return PARTNERS.map((partner, i) => (
      <div key={partner} className={classes.partner}>
        <img src={`${cdnRoot}/images/partners/${partner}.png`} />
      </div>
    ));
  }

  return (
    <div className={classes.root} ref={containerRef}>
      <Fade
        in={fadeIn}
        timeout={{
          enter: 1500,
        }}>
        <div className={classes.container}>{renderPartners()}</div>
      </Fade>
    </div>
  );
};

export default Partners;

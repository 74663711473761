import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import { Fade, Theme, Typography } from '@mui/material';
import theme, { cdnRoot } from '../theme';
import { secsToDays } from '../utils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: '#171717',
    borderRadius: '12px',
    minHeight: '350px',
    width: '280px',
    display: 'flex',
    padding: '1.5rem',
    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
    border: 'none',
    flexDirection: 'column',
    gap: '1.5rem',
    alignItems: 'center',

    '& h6': {
      color: '#737373',
      fontSize: '1.5rem',
    },
  },

  img: {
    borderRadius: '12px',
    height: '230px',
  },

  cont: {
    width: '100%',
    gap: '1rem',
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'space-between',
  },

  unlocksIn: {
    textAlign: 'left',

    '& p': {
      color: '#525252',
      fontSize: '0.8rem',
    },

    '& h5': {
      fontSize: '1.35rem',
      color: theme.palette.primary.main,
    },
  },
}));

export interface IToken {
  id: number;
  image: string;
  name: string;
  description: string;
  external_url: string;
}

interface ITokenCardProps {
  tokenId: number;
  unlocksIn?: number;
  isSelected?: boolean;
  onClick?: () => void;
  fadeInDuration: number;
}

const TokenCard: FunctionComponent<ITokenCardProps> = (props) => {
  const classes = useStyles();
  const { tokenId, unlocksIn, isSelected, onClick, fadeInDuration } = props;

  const haveUnlocksIn = unlocksIn !== undefined;
  const claimable = haveUnlocksIn && (unlocksIn as number) <= 0;
  const unlocksAt = haveUnlocksIn ? secsToDays(unlocksIn) : null;

  console.log(unlocksAt, unlocksIn);

  function renderUnlock() {
    if (claimable) {
      return (
        <>
          <Typography variant={'body1'}>Staking Status</Typography>
          <Typography variant={'h5'}>Finished</Typography>
        </>
      );
    }

    if (!!unlocksIn) {
      return (
        <>
          <Typography variant={'body1'}>Unlocks in</Typography>
          <Typography variant={'h5'}>{unlocksAt} days</Typography>
        </>
      );
    }
  }

  return (
    <Fade in={true} timeout={fadeInDuration}>
      <button
        className={classes.root}
        onClick={onClick}
        style={{
          border: `2px solid ${
            isSelected ? theme.palette.primary.main : '#171717'
          }`,
          cursor: !!onClick ? 'pointer' : 'default',
        }}>
        <img
          src={`${cdnRoot}/tokens/images/${tokenId}.png`}
          alt={`img-${tokenId}`}
          className={classes.img}
        />
        <div className={classes.cont}>
          <Typography variant={'h6'}>#{tokenId}</Typography>
          {haveUnlocksIn && (
            <div className={classes.unlocksIn}>{renderUnlock()}</div>
          )}
        </div>
      </button>
    </Fade>
  );
};

export default TokenCard;

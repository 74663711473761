import React, { FunctionComponent, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { CircularProgress, Fade, Theme, Typography } from '@mui/material';
import BaseButton from './BaseButton';
import { truncateAddress } from '../utils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: '30px 10px !important',

    [theme.breakpoints.down('sm')]: {
      margin: '30px 0 !important',
    },
  },
}));

interface IConnectWalletButton {
  currentAccount: string | null | undefined;
  loading: boolean;
  getAndSetAccount: () => any;
}

const ConnectWalletButton: FunctionComponent<IConnectWalletButton> = (
  props,
) => {
  const classes = useStyles();

  const { currentAccount, loading, getAndSetAccount } = props;
  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShow(true);
    }, 400);

    return () => clearTimeout(timeout);
  }, []);

  function getButtonText() {
    if (!currentAccount) return 'Connect Wallet';

    if (!!currentAccount)
      return (
        <Typography variant={'h6'}>
          {truncateAddress(currentAccount)}
        </Typography>
      );

    if (loading) return <CircularProgress size={22.5} color={'inherit'} />;
  }

  async function handleClick() {
    if (!currentAccount) {
      return await getAndSetAccount();
    }
  }

  return (
    <div className={classes.root}>
      <Fade in={show} timeout={400}>
        <div>
          <BaseButton onClick={handleClick} disabled={!!currentAccount}>
            <div>{getButtonText()}</div>
          </BaseButton>
        </div>
      </Fade>
    </div>
  );
};

export default ConnectWalletButton;

import { createTheme } from '@mui/material/styles';

export const headerFont = 'Cinzel';
export const bodyFont = 'NanumGothic';

export const white = '#fbfcf7';
export const black = '#16181b';

export const boxShadow = 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px';

function getCdnRoot(): string {
  if (window.location.hostname === 'localhost') {
    return `http://${window.location.host}`;
  }

  if (window.location.hostname === 'thisthingofours.com') {
    return 'https://thisthingofours.com';
  }

  return 'https://this-thing-of-ours.vercel.app';
}

export const cdnRoot = getCdnRoot();

const primary = {
  light: '#cfe5e7',
  main: '#d63031',
  dark: '#212121',
  contrastText: black,
};

const secondary = {
  light: '#cfe5e7',
  main: '#10B981',
  dark: '#212121',
  contrastText: black,
};

const theme = createTheme({
  palette: {
    primary,
    secondary,
  },
  typography: {
    fontFamily: [headerFont, bodyFont, 'sans-serif'].join(','),
    h1: {
      fontSize: '4rem',
      fontFamily: headerFont,
    },
    h2: {
      fontSize: '3.5rem',
      fontFamily: headerFont,
    },
    h3: {
      fontSize: '2.5rem',
      fontFamily: headerFont,
    },
    h4: {
      fontSize: '2rem',
      fontFamily: headerFont,
    },
    h5: {
      fontSize: '1.5rem',
      fontFamily: headerFont,
    },
    h6: {
      fontSize: '1.1rem',
      fontFamily: headerFont,
    },
    body1: {
      fontFamily: bodyFont,
      fontSize: '1rem',
    },
    body2: {
      fontFamily: bodyFont,
      fontSize: '0.8rem',
    },
    button: {
      fontFamily: headerFont,
    },
  },
});

export default theme;

import React, { useEffect, useState } from 'react';

const useFadeIn = (
  eleRef: React.RefObject<HTMLDivElement>,
  scrollOffset: number,
) => {
  const [fadeIn, setFadeIn] = useState<boolean>();

  useEffect(() => {
    if (!fadeIn) {
      window.addEventListener('scroll', handleScroll);
    }

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  function handleScroll(e: Event) {
    if (!eleRef.current) return;

    const window = e.currentTarget as Window;
    const rect = eleRef.current.getBoundingClientRect();
    const elemTop = rect.top;
    const elemBottom = rect.bottom;

    if (elemTop + scrollOffset < window.innerHeight && elemBottom >= 0) {
      setFadeIn(true);
    }
  }

  return [fadeIn];
};

export default useFadeIn;

import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import { Fade, Theme, Typography } from '@mui/material';
import theme from '../theme';
import TokenCard from '../components/TokenCard';
import { useStaking } from '../hooks/useStaking';
import ConnectWalletButton from '../components/ConnectWalletButton';
import StakingLockTimes from '../components/StakingLockTimes';
import StakingButton from '../components/StakingButton';
import UnstakeButton from '../components/UnstakeButton';
import { useConnectAccount } from '../hooks/useConnectAccount';
import { ToastContainer } from 'react-toast';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    minHeight: '100vh',
    width: '100%',
    maxWidth: '1400px',
    margin: '0 auto',
    padding: '10rem 4rem',
    paddingBottom: '4rem',
    flexDirection: 'column',
    marginBottom: 'auto',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    textAlign: 'center',
    width: '100%',
    gap: '1rem',
    paddingBottom: '2rem',
    minHeight: '147px',

    '& p': {
      maxWidth: '600px',
    },
  },
  section: {
    minHeight: '485px',
    width: '100%',
    padding: '1.5rem',
    backgroundColor: '#111',
    marginBottom: '3rem',
    borderRadius: '12px',
    border: '1px solid #222',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
    position: 'relative',
    paddingBottom: '4rem',

    '& h2': {
      color: '#404040',
      fontSize: '2.5rem',
    },
  },
  cont: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '1.75rem',
    marginTop: '1.75rem',
  },
  placeholder: {
    fontSize: '1.2rem !important',
    color: '#404040',
    opacity: 0.7,
  },
}));

declare let window: any;

interface StakingProps {}

const Staking: FunctionComponent<StakingProps> = (props) => {
  const classes = useStyles();

  if (!window.ethereum)
    return (
      <Typography variant={'body1'} className={classes.placeholder}>
        Install Metamask to continue
      </Typography>
    );

  const { currentAccount, loading, getAndSetAccount } = useConnectAccount();

  const {
    stakedTokens,
    unstakedTokens,
    selectedForStaking,
    selectForStaking,
    lockTimes,
    selectedLockTime,
    setSelectedLockTime,
    stakeTokens,
    isPendingStaking,
    isPendingUnStaking,
    isApproving,
    selectedForUnStaking,
    selectForUnStaking,
    unStakeTokens,
  } = useStaking(currentAccount);

  const canPerformAction =
    !isApproving && !isPendingStaking && !isPendingUnStaking;

  function renderUnstakedTokens() {
    if (!unstakedTokens) return null;

    if (unstakedTokens.length === 0) {
      return (
        <Fade in={true} timeout={500}>
          <Typography variant={'body1'} className={classes.placeholder}>
            No unstaked TTOOs found
          </Typography>
        </Fade>
      );
    }

    return unstakedTokens.map((token, i) => {
      const isSelected = !!selectedForStaking.find(({ id }) => id === token.id);

      return (
        <TokenCard
          key={`unstaked-token-${token.id}`}
          tokenId={token.id}
          isSelected={isSelected}
          fadeInDuration={500}
          onClick={
            !canPerformAction
              ? undefined
              : () => selectForStaking(token, isSelected)
          }
        />
      );
    });
  }

  function renderStakedTokens() {
    if (!stakedTokens) return null;

    if (stakedTokens.length === 0) {
      return (
        <Fade in={true} timeout={500}>
          <Typography variant={'body1'} className={classes.placeholder}>
            No staked TTOOs found
          </Typography>
        </Fade>
      );
    }

    return stakedTokens.map((token, i) => {
      const { id, unlocksIn } = token;
      const claimable = unlocksIn <= 0;
      const isSelected =
        claimable && !!selectedForUnStaking.find((_token) => id === _token.id);
      const canClick = claimable && canPerformAction;

      return (
        <TokenCard
          key={`staked-token-${id}`}
          tokenId={id}
          unlocksIn={unlocksIn}
          isSelected={isSelected}
          fadeInDuration={500}
          onClick={
            !canClick ? undefined : () => selectForUnStaking(token, isSelected)
          }
        />
      );
    });
  }

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography variant={'h1'} color={theme.palette.primary.main}>
          Stake your TTOOs
        </Typography>
        <ConnectWalletButton
          currentAccount={currentAccount}
          loading={loading}
          getAndSetAccount={getAndSetAccount}
        />
      </div>

      <div className={classes.section}>
        <Typography variant={'h2'}>Staked TTOOs</Typography>
        <div className={classes.cont}>{renderStakedTokens()}</div>
        <UnstakeButton
          isPendingUnStaking={isPendingUnStaking}
          selectedForUnStaking={selectedForUnStaking}
          unStakeTokens={unStakeTokens}
        />
      </div>

      <div className={classes.section}>
        <Typography variant={'h2'}>Unstaked TTOOs</Typography>
        {!!lockTimes && !!unstakedTokens && unstakedTokens.length !== 0 && (
          <StakingLockTimes
            lockTimes={lockTimes}
            selectedLockTime={selectedLockTime}
            setSelectedLockTime={setSelectedLockTime}
          />
        )}
        <div className={classes.cont}>{renderUnstakedTokens()}</div>
        <StakingButton
          selectedForStaking={selectedForStaking}
          stakeTokens={stakeTokens}
          isPendingStaking={isPendingStaking}
          isApproving={isApproving}
        />
      </div>
      <ToastContainer position={'top-right'} delay={5000} />
    </div>
  );
};

export default Staking;

import React, { FunctionComponent, useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Fade, Theme } from '@mui/material';
import { cdnRoot } from '../theme';
import MintButton from './MintButton';
import BaseButton from './BaseButton';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: 'rgba(0, 0, 0, 1)',
    minWidth: '100vw',
    height: '100vh',
    minHeight: '600px',
    background: `linear-gradient(155deg, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 1)), url(${cdnRoot}/images/landing2.jpg)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    overflow: 'hidden',
    position: 'relative',
    color: 'white',
  },
  cover: {
    backgroundColor: 'rgba(0, 0, 0, 1)',
    minWidth: '100vw',
    height: '100vh',
    minHeight: '600px',
    opacity: 1,
    position: 'absolute',
    left: '0',
    top: '0',
    overflow: 'hidden',
    zIndex: 1000,
  },
  hidden: {
    opacity: 0,
    transition: 'opacity 1s ease-in-out',
  },
  info: {
    position: 'absolute',
    left: '20%',
    top: '20%',

    [theme.breakpoints.down('lg')]: {
      maxWidth: '400px',
    },

    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      left: 0,
      right: 0,
      marginRight: 'auto',
      marginLeft: 'auto',
      maxWidth: '280px',

      '& img': {
        width: '100%',
        margin: '2rem auto',
      },
    },

    '@media (max-height: 450px)': {
      top: '25%',
    },
  },
  title: {
    '& span': {
      color: '#d63031',
    },

    [theme.breakpoints.down('lg')]: {
      maxWidth: '450px !important',
    },

    [theme.breakpoints.down('sm')]: {
      fontSize: '3rem !important',
      maxWidth: '300px !important',
    },

    '@media (max-height: 450px)': {
      maxWidth: '250px !important',
      fontSize: '2.75rem !important',
    },
  },
  sub: {
    margin: '5px 10px !important',
    opacity: '0.5',

    [theme.breakpoints.down('sm')]: {
      margin: '20px 10px !important',
    },
  },
  init: {
    color: 'white',
    opacity: '0.2',
    margin: '5px 10px !important',
  },
  buttonCont: {
    marginTop: '-8rem',
    marginLeft: '2rem',

    [theme.breakpoints.down('sm')]: {
      marginLeft: '0',
      marginTop: '-7rem',
    },
  },
}));

interface LandingProps {}

const Landing: FunctionComponent<LandingProps> = (props) => {
  const classes = useStyles();
  const enableMint = false;

  const [fadeInSub, setFadeInSub] = useState<boolean>(false);
  const [fadeInButton, setFadeInButton] = useState<boolean>(false);

  useEffect(() => {
    const fadeInButtonId = setTimeout(() => {
      setFadeInButton(true);
    }, 1250);

    const fadeInSubId = setTimeout(() => {
      setFadeInSub(true);
    }, 500);

    return () => {
      clearInterval(fadeInButtonId);
      clearInterval(fadeInSubId);
    };
  }, []);

  function renderFadeInSub() {
    return (
      <Fade
        in={fadeInSub}
        timeout={{
          enter: 2000,
        }}>
        <div>
          <img src={`${cdnRoot}/images/logo_transparent.png`} />
        </div>
      </Fade>
    );
  }

  function renderFadeInButton() {
    return (
      <Fade
        in={fadeInButton}
        timeout={{
          enter: 1500,
        }}>
        <div className={classes.buttonCont}>
          {enableMint ? (
            <MintButton />
          ) : (
            <div
              style={{
                marginTop: '1.5rem',
                display: 'flex',
                gap: '0.3rem',
              }}>
              <BaseButton disabled={true}>Sold Out</BaseButton>
              <BaseButton
                onClick={() =>
                  window.open(
                    'https://opensea.io/collection/this-thing-of-ours-nft',
                    '_blank',
                  )
                }>
                Buy on OpenSea
              </BaseButton>
            </div>
          )}
        </div>
      </Fade>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.info}>
        {renderFadeInSub()}
        {renderFadeInButton()}
      </div>
    </div>
  );
};

export default Landing;

import React, { FunctionComponent, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import { Fade, Theme, Typography } from '@mui/material';
import { cdnRoot } from '../theme';
import useFadeIn from '../hooks/useFadeIn';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100vh',
    minHeight: '650px',
    maxHeight: '955px',
    width: '100%',
    backgroundColor: '#f1f2f6',
    background: `linear-gradient(155deg, rgb(241, 246, 249, 1), rgba(241, 246, 249, 0.97), rgba(241, 246, 249, 0.85), rgba(241, 246, 249, 0.95)), url(${cdnRoot}/images/manifesto.jpg)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
  },
  container: {
    width: '100%',
    maxWidth: '1200px',
    padding: '4.5rem 2rem 6rem 2rem',
    marginTop: '100px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    marginBottom: '200px',

    [theme.breakpoints.down('lg')]: {
      padding: '6rem 6rem 7rem 6rem',
      marginTop: '0',
      marginBottom: '0',
      '& h1': {
        fontSize: '3rem !important',
      },
    },

    [theme.breakpoints.down('sm')]: {
      padding: '3rem 2rem 3rem 2rem',
    },

    '& h1': {
      [theme.breakpoints.down('lg')]: {
        fontSize: '3rem !important',
      },
      '@media (max-height: 450px)': {
        fontSize: '2.75rem !important',
      },
    },
  },
  text: {
    maxWidth: '750px',
    marginTop: '3.5rem !important',
    lineHeight: '2 !important',

    [theme.breakpoints.down('sm')]: {
      fontSize: '14px !important',
    },
  },
  titleCont: {
    position: 'relative',
  },
  illustration: {
    width: '200px',
    height: '5px',
    backgroundColor: '#d63031',
  },
}));

interface ManifestoProps {}

const Manifesto: FunctionComponent<ManifestoProps> = (props) => {
  const classes = useStyles();

  const containerRef = useRef<HTMLDivElement>(null);
  const [fadeIn] = useFadeIn(containerRef, 200);

  return (
    <div className={classes.root} ref={containerRef}>
      <div className={classes.container}>
        <div className={classes.titleCont}>
          <Fade
            in={fadeIn}
            timeout={{
              enter: 1500,
            }}>
            <div>
              <Typography variant={'h1'}>Manifesto</Typography>
              <div className={classes.illustration} />
            </div>
          </Fade>
        </div>
        <Fade
          in={fadeIn}
          timeout={{
            enter: 2500,
          }}>
          <div>
            <Typography variant={'body1'} className={classes.text}>
              This Thing of Ours is a NFT collection not only for investors but
              gamers and builders alike. Having the P2E & NFT playbook by your
              side has the past year proven itself to be the premier way of
              becoming a successful web3 participant. Our vision has always been
              to create an engaged community provided with all the necessary
              tools to navigate the space. This will soon include exclusive
              crypto gaming reports, video drops, AMA’s with industry leaders
              and ability to network with top-tier individuals and more... The
              Epicenter of Crypto Gaming
              <br />
              <br />- Arrivederci
            </Typography>
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default Manifesto;

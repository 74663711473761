import React, { FunctionComponent, useEffect, useState } from 'react';
import IconTwitter from '../assets/IconTwitter';
import IconDiscord from '../assets/IconDiscord';
import IconOpenSea from '../assets/IconOpenSea';
import { makeStyles } from '@mui/styles';
import { Button, Fade, Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'fixed',
    top: '40%',
    right: '40px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '25px',

    [theme.breakpoints.down('md')]: {
      position: 'absolute',
      flexDirection: 'row',
      justifyContent: 'center',
      top: '30px',
      right: '0',
      left: '0',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  iconWrapper: {
    borderRadius: '50% !important',
    border: '2px solid #d63031 !important',
    padding: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '50px !important',
    minWidth: '50px !important',
    backgroundColor: 'rgba(0, 0, 0, 0.9) !important',
  },
}));

interface SocialsProps {}

const Socials: FunctionComponent<SocialsProps> = (props) => {
  const classes = useStyles();

  const [fadeIn, setFadeIn] = useState<boolean>(false);

  useEffect(() => {
    const fadeInId = setTimeout(() => {
      setFadeIn(true);
    }, 1500);

    return () => {
      clearInterval(fadeInId);
    };
  }, []);

  return (
    <div className={classes.root}>
      <Fade
        in={fadeIn}
        timeout={{
          enter: 2000,
        }}>
        <Button
          className={classes.iconWrapper}
          onClick={() =>
            window.open(
              'https://opensea.io/collection/this-thing-of-ours-nft',
              '_blank',
            )
          }>
          <IconOpenSea height={20} width={20} />
        </Button>
      </Fade>
      <Fade
        in={fadeIn}
        timeout={{
          enter: 3000,
        }}>
        <Button
          className={classes.iconWrapper}
          onClick={() => window.open('https://twitter.com/ttoonft', '_blank')}>
          <IconTwitter height={20} width={20} />
        </Button>
      </Fade>
      <Fade
        in={fadeIn}
        timeout={{
          enter: 4000,
        }}>
        <Button
          className={classes.iconWrapper}
          onClick={() =>
            window.open('https://discord.gg/KubNrYEU5u', '_blank')
          }>
          <IconDiscord height={20} width={20} />
        </Button>
      </Fade>
    </div>
  );
};

export default Socials;

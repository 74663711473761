import * as React from 'react';

function SvgIconTwitter(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='1653.93 27.02 27 21.95'
      {...props}>
      <path
        fill='#d63031'
        d='M1678.16 32.49c.02.24.02.48.02.72 0 7.32-5.57 15.76-15.76 15.76-3.01 0-5.96-.86-8.49-2.48a11.092 11.092 0 008.2-2.3 5.548 5.548 0 01-5.18-3.85c.83.16 1.69.13 2.5-.1a5.537 5.537 0 01-4.44-5.43v-.07a5.4 5.4 0 002.51.69 5.554 5.554 0 01-1.71-7.4c2.81 3.46 6.96 5.57 11.42 5.79-.45-1.92.16-3.94 1.6-5.29a5.546 5.546 0 017.84.24c1.24-.24 2.43-.7 3.52-1.35a5.53 5.53 0 01-2.44 3.06c1.1-.13 2.17-.42 3.18-.87-.75 1.13-1.69 2.1-2.77 2.88z'
      />
    </svg>
  );
}

export default SvgIconTwitter;

import { intervalToDuration } from 'date-fns';
import Moralis from 'moralis';
import { IToken } from './components/TokenCard';

export const truncateAddress = (address: string): string => {
  if (!address) return 'No Account';
  const match = address.match(
    /^(0x[a-zA-Z0-9]{2})[a-zA-Z0-9]+([a-zA-Z0-9]{2})$/,
  );

  if (!match) return address;
  return `${match[1]}…${match[2]}`;
};

export const formatNumOf = (num: number | undefined, of: string) => {
  const _num = num ?? 0;
  return `${_num} ${of}${_num === 1 ? '' : 's'}`;
};

export const getDaysUntil = (date: number) => {
  const now = new Date().getTime();

  const { days, hours, minutes, seconds } = intervalToDuration({
    start: now,
    end: now + date,
  });

  if (minutes === 0) return formatNumOf(seconds ?? 1, 'sec');
  if (hours === 0) return formatNumOf(minutes ?? 1, 'min');
  if (days === 0) return formatNumOf(hours ?? 1, 'hour');
  return formatNumOf(days, 'day');
};

export const secsToDays = (secs: number) => Math.floor(secs / 86400);

export const getNFTs = async (
  chain: string,
  contractAddr: string,
  address: string,
) => {
  try {
    await Moralis.start({
      apiKey: process.env.REACT_APP_MORALIS_API_KEY,
    });

    const response = await Moralis.EvmApi.nft.getWalletNFTs({
      address,
      chain,
      format: 'decimal',
      mediaItems: false,
      tokenAddresses: [contractAddr],
    });

    const tokens = response.raw.result;
    if (!tokens) return [];

    console.log(tokens);

    return tokens
      .map((token: any) => ({ ...JSON.parse(token.metadata) }))
      .filter((token: IToken) => Object.keys(token).length !== 0)
      .sort((a: IToken, b: IToken) => a.id - b.id);
  } catch (e) {
    console.error(e);
  }
};

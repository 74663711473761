import React, { FunctionComponent } from 'react';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Home from './pages/Home';
import NotFound from './pages/NotFound';
import Staking from './pages/Staking';
import Navbar from './components/Navbar';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: '#111010',
    position: 'relative',
    minHeight: '100vh',
    overflowX: 'hidden',
  },
}));

interface AppProps {}

const App: FunctionComponent<AppProps> = (props) => {
  const classes = useStyles();

  return (
    <main className={classes.root}>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/staking' element={<Staking />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </main>
  );
};

export default App;

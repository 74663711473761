import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import { Theme, Typography } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
  },
  title: {
    color: '#d63031 !important',
    fontSize: '6rem !important',
  },
  text: {
    marginTop: '30px !important',
    color: '#424242 !important',
    textAlign: 'center',
  },
}));

interface NotFoundProps {}

const NotFound: FunctionComponent<NotFoundProps> = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div>
        <Typography variant={'h1'} className={classes.title}>
          404
        </Typography>
        <Typography variant={'body1'} className={classes.text}>
          Page Not Found
        </Typography>
      </div>
    </div>
  );
};

export default NotFound;

import React, { FunctionComponent } from 'react';
import Landing from '../components/Landing';
import Manifesto from '../components/Manifesto';
import Faq from '../components/Faq';
import Footer from '../components/Footer';
import Socials from '../components/Socials';
import Partners from '../components/Partners';

interface HomeProps {}

const Home: FunctionComponent<HomeProps> = (props) => {
  return (
    <div>
      <Landing />
      <Manifesto />
      <Faq />
      <Partners />
      <Footer />
      <Socials />
    </div>
  );
};

export default Home;

import { MerkleTree } from 'merkletreejs';
import { ethers } from 'ethers';

const { keccak256 } = ethers.utils;

export const getProof = (senderAddr: string, whitelist: string[]) => {
  const leaves = whitelist.map((address) => keccak256(address));
  const tree = new MerkleTree(leaves, keccak256, { sort: true });

  return tree.getHexProof(keccak256(senderAddr));
};

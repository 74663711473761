import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import { Button, Theme, Typography } from '@mui/material';
import { cdnRoot } from '../theme';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'absolute',
    top: 0,
    height: '120px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'transparent',
    padding: '0 3rem',
    zIndex: 1,
  },

  cont: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',

    '& a': {
      textDecoration: 'none',
    },
  },

  link: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    padding: '10px 25px !important',
    borderRadius: '4px !important',
    color: 'white !important',
  },
}));

interface ILink {
  text: string;
  route: string;
}

const LINKS: ILink[] = [
  {
    text: 'Stake TTOO',
    route: '/staking',
  },
];

interface NavbarProps {}

const Navbar: FunctionComponent<NavbarProps> = (props) => {
  const classes = useStyles();
  const location = useLocation();

  function renderLinks() {
    return LINKS.map(({ text, route }) => {
      if (location.pathname === route) return null;

      return (
        <Link key={text} to={route}>
          <Button className={classes.link}>
            <Typography variant={'h6'} fontSize={'1rem'} marginTop={0.2}>
              {text}
            </Typography>
          </Button>
        </Link>
      );
    });
  }

  return (
    <nav className={classes.root}>
      <Link to={'/'}>
        <img src={`${cdnRoot}/images/logo_transparent.png`} height={180} />
      </Link>
      <div className={classes.cont}>{renderLinks()}</div>
    </nav>
  );
};

export default Navbar;

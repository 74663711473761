import React, { FunctionComponent, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Button, CircularProgress, Theme, Typography } from '@mui/material';
import { ethers } from 'ethers';
import ThisThingOfOurs from '../abi/ThisThingOfOurs.json';
import USDC from '../abi/USDC.json';
import CapoWhitelist from '../whitelists/capo.json';
import SoliderWhitelist from '../whitelists/soldier.json';
import OgWhitelist from '../whitelists/og.json';
import { getProof } from '../merkleTree';
import MintDialog, { DialogStatus } from './MintDialog';
import BaseButton from './BaseButton';
import { useConnectAccount } from '../hooks/useConnectAccount';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: '30px 10px !important',

    [theme.breakpoints.down('sm')]: {
      margin: '30px 0 !important',
    },
  },
  supply: {
    marginTop: '20px !important',
    marginLeft: '3px !important',
    opacity: 0.9,
    fontSize: '0.9rem !important',
  },
  account: {
    color: theme.palette.primary.main,
    marginTop: '10px !important',
  },
  buttonCont: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  updateButton: {
    backgroundColor: 'rgba(0, 0, 0, 0.7) !important',
    height: '50px !important',
    width: '50px !important',
    minWidth: '45px !important',
    border: `2px solid ${theme.palette.primary.main} !important`,
    fontSize: '20px !important',
    transition: 'all 0.2s linear !important',
    borderRadius: '4px !important',

    '&:disabled': {
      color: `${theme.palette.primary.main} !important`,
      opacity: 0.3,
    },
  },
}));

interface MintButtonProps {}

declare let window: any;

export enum WhitelistType {
  None = 0,
  Soldier = 1,
  Og = 2,
  Capo = 3,
}

const TTOO_ADDR = '0xF6ee484f82f28d69688f37fe90Af514ce212b7c3'; // main-net: 0xF6ee484f82f28d69688f37fe90Af514ce212b7c3
const USDC_ADDR = '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48'; // main-net: 0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48

const MINT_PRICE = 250e6; // 250 USDC
const CAPO_SUPPLY = 158;
const TOTAL_SUPPLY = 2000;
const QUERY_SUPPLY_INTERVAL = 5000;

const SOLDIER_WL_MINT_AMOUNT = 2;
const OG_WL_MINT_AMOUNT = 3;
const CAPO_WL_MINT_AMOUNT = 3;

const MintButton: FunctionComponent<MintButtonProps> = (props) => {
  const classes = useStyles();

  if (!window.ethereum) {
    return null;
  }

  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();

  const usdcContract = new ethers.Contract(USDC_ADDR, USDC.abi, signer);
  const ttooContract = new ethers.Contract(
    TTOO_ADDR,
    ThisThingOfOurs.abi,
    signer,
  );

  const [isPublicSale, setIsPublicSale] = useState<boolean | null>(null);
  const [isWhitelistSale, setIsWhitelistSale] = useState<boolean | null>(null);
  const [whitelist, setWhitelist] = useState<WhitelistType>(WhitelistType.None);
  const [status, setStatus] = useState<DialogStatus>(DialogStatus.None);

  const [totalAmountMinted, setTotalAmountMinted] = useState<number>(0);
  const [amountMinted, setAmountMinted] = useState<number>(0);
  const [numToMint, setNumToMint] = useState<number>(1);
  const [caposMinted, setCaposMinted] = useState<number>(0);
  const [soldiersMinted, setSoldiersMinted] = useState<number>(0);

  const { currentAccount, loading, setLoading, getAndSetAccount } =
    useConnectAccount();

  const soldOut = totalAmountMinted >= TOTAL_SUPPLY;
  const totalPrice = (MINT_PRICE / 1000000) * numToMint;
  const saleNotStarted = !isWhitelistSale && !isPublicSale;

  document.body.style.overflow =
    status === DialogStatus.None ? 'auto' : 'hidden';

  useEffect(() => {
    if (!currentAccount) return;

    console.log(getProof(currentAccount, SoliderWhitelist.whitelist));

    const getAndSetPublicSaleStatus = async () => {
      const isPublicSale = await isPublicSaleActive();
      setIsPublicSale(isPublicSale);
    };

    const getAndSetWhitelistSaleStatus = async () => {
      const isWhitelistSale = await ttooContract.whitelistSaleActive();
      setIsWhitelistSale(isWhitelistSale);
    };

    const supplyInterval = setInterval(async () => {
      if (!currentAccount) return;
      await getAndSetTotalAmountMinted();
      await getAndSetPublicSaleStatus();
      await getAndSetWhitelistSaleStatus();
    }, QUERY_SUPPLY_INTERVAL);

    const loadContractData = async () => {
      if (!isPublicSale) {
        checkIfWhitelisted();
        await getAndSetAmountMinted();
      }

      await getAndSetTotalAmountMinted();
      await getAndSetPublicSaleStatus();
      setLoading(false);
    };

    loadContractData();

    return () => clearInterval(supplyInterval);
  }, [currentAccount]);

  async function getAndSetTotalAmountMinted() {
    const nextCapo = await ttooContract.nextCapo();
    const nextId = await ttooContract.nextId();
    const minted = Math.abs(CAPO_SUPPLY - nextCapo - nextId);
    const supplyLeft = await ttooContract.publicSupplyAvailable();
    setTotalAmountMinted(TOTAL_SUPPLY);
  }

  async function getAndSetAmountMinted() {
    const claimedCapos = parseInt(
      await ttooContract.claimedCapos(currentAccount),
    );
    const claimedSoldiers = parseInt(
      await ttooContract.claimedSoldiers(currentAccount),
    );
    const totalClaimed = claimedCapos + claimedSoldiers;
    setCaposMinted(claimedCapos);
    setSoldiersMinted(claimedSoldiers);
    setAmountMinted(totalClaimed);
  }

  async function isPublicSaleActive() {
    const isPublicSale = await ttooContract.publicSaleActive();
    return isPublicSale;
  }

  async function isWhitelistSaleActive() {
    const isWhitelistSale = await ttooContract.whitelistSaleActive();
    return isWhitelistSale;
  }

  function checkIfWhitelisted() {
    const isCapoWl = CapoWhitelist.whitelist
      .map((address) => address.toUpperCase())
      .includes(currentAccount?.toUpperCase() as string);

    if (isCapoWl) {
      return setWhitelist(WhitelistType.Capo);
    }

    const isSoliderWl = SoliderWhitelist.whitelist
      .map((address) => address.toUpperCase())
      .includes(currentAccount?.toUpperCase() as string);

    if (isSoliderWl) {
      return setWhitelist(WhitelistType.Soldier);
    }

    const isOgWl = OgWhitelist.whitelist
      .map((address) => address.toUpperCase())
      .includes(currentAccount?.toUpperCase() as string);

    if (isOgWl) {
      return setWhitelist(WhitelistType.Og);
    }

    setWhitelist(WhitelistType.None);
  }

  async function handleClick() {
    if (!currentAccount) {
      return await getAndSetAccount();
    }

    await mint();
  }

  async function approveUSDC() {
    setStatus(DialogStatus.Approving);
    let status = false;

    try {
      const usdcApproval = await usdcContract.approve(
        TTOO_ADDR,
        MINT_PRICE * numToMint,
      );
      const receipt = await provider.waitForTransaction(usdcApproval.hash, 1);
      status = Boolean(receipt.status);
    } catch (e) {
      console.error(e);
    }

    return status;
  }

  async function mint() {
    const approvedUSDC = await approveUSDC();

    if (approvedUSDC) {
      setStatus(DialogStatus.Minting);

      try {
        const { numSoldiers, numCapos } = getMintNums();

        const mint = await (isPublicSale
          ? ttooContract.purchase(numToMint)
          : ttooContract.mint(
              whitelist,
              numSoldiers,
              numCapos,
              getWhitelistProof(),
            ));

        const { status } = await provider.waitForTransaction(mint.hash, 1);

        setStatus(status ? DialogStatus.Minted : DialogStatus.MintError);
        if (status) {
          if (!isPublicSale) {
            setAmountMinted((prevState) => prevState + numToMint);
          }

          setCaposMinted(caposMinted);
          setSoldiersMinted(soldiersMinted);
          setNumToMint(1);
        }
      } catch (e) {
        console.error(e);
        setStatus(DialogStatus.MintError);
      }
    } else {
      setStatus(DialogStatus.None);
    }
  }

  function getMintNums() {
    let numCapos = 0;
    let numSoldiers = 0;

    if (whitelist === WhitelistType.Capo) {
      numCapos = caposMinted === 1 ? 0 : 1;
      numSoldiers = numToMint - numCapos;
    } else {
      numSoldiers = numToMint;
    }

    return { numSoldiers, numCapos };
  }

  function getWhitelistProof() {
    let collection = SoliderWhitelist.whitelist;
    if (whitelist === WhitelistType.Capo) {
      collection = CapoWhitelist.whitelist;
    } else if (whitelist === WhitelistType.Og) {
      collection = OgWhitelist.whitelist;
    }

    return getProof(currentAccount as string, collection);
  }

  function didMintMaxAmount() {
    if (isPublicSale) return false;
    return amountMinted === getMaxMintNumber();
  }

  function notOnWl() {
    return !isPublicSale && whitelist === WhitelistType.None;
  }

  function isDisabled() {
    if (!currentAccount) return false;

    if (loading) return true;
    if (soldOut) return true;
    if (saleNotStarted) return true;
    if (didMintMaxAmount()) return true;
    if (notOnWl()) return true;

    return false;
  }

  function formatWalletAddr() {
    const addr = currentAccount;
    return `${addr?.slice(0, 5)}...${addr?.slice(
      addr?.length - 4,
      addr?.length,
    )}`;
  }

  function getButtonText() {
    if (!currentAccount) return 'Connect Wallet';
    if (loading) return <CircularProgress size={22.5} color={'primary'} />;
    if (soldOut) return 'SOLD OUT!';
    if (saleNotStarted) return 'Starting soon...';
    if (didMintMaxAmount()) {
      return `${amountMinted}/${getMaxMintNumber()} Minted`;
    }
    if (notOnWl()) return 'Not whitelisted';

    const maxMintNum = getMaxMintNumber();
    const canMintMoreThanOne = isPublicSale || amountMinted < maxMintNum;
    const btnText = canMintMoreThanOne
      ? `${numToMint}/${maxMintNum - (isPublicSale ? 0 : amountMinted)}`
      : '';

    return (
      <div>
        Mint {btnText}
        <br />
        <span>{totalPrice} USDC</span>
      </div>
    );
  }

  function getMaxMintNumber() {
    if (!isPublicSale) {
      if (whitelist === WhitelistType.Soldier) return SOLDIER_WL_MINT_AMOUNT;
      if (whitelist === WhitelistType.Capo) return CAPO_WL_MINT_AMOUNT;
      if (whitelist === WhitelistType.Og) return OG_WL_MINT_AMOUNT;
    }

    return 4;
  }

  function renderIncrementButton() {
    if (didMintMaxAmount() || !currentAccount || notOnWl() || loading)
      return null;

    return (
      <Button
        disabled={
          numToMint + (isPublicSale ? 0 : amountMinted) === getMaxMintNumber()
        }
        className={classes.updateButton}
        onClick={() => setNumToMint((prevState) => prevState + 1)}>
        +
      </Button>
    );
  }

  function renderDecrementButton() {
    if (didMintMaxAmount() || !currentAccount || notOnWl() || loading)
      return null;

    return (
      <Button
        disabled={numToMint === 1}
        className={classes.updateButton}
        onClick={() => setNumToMint((prevState) => prevState - 1)}>
        -
      </Button>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.buttonCont}>
        {!soldOut && !saleNotStarted && renderDecrementButton()}
        <BaseButton onClick={handleClick} disabled={isDisabled()}>
          {getButtonText()}
        </BaseButton>
        {!soldOut && !saleNotStarted && renderIncrementButton()}
      </div>
      {currentAccount && (
        <>
          <Typography variant={'h6'} className={classes.supply}>
            {totalAmountMinted} / {TOTAL_SUPPLY} Minted
          </Typography>
          <Typography variant={'h6'} className={classes.account}>
            {formatWalletAddr()}
          </Typography>
        </>
      )}
      <MintDialog
        status={status}
        close={() => setStatus(DialogStatus.None)}
        totalPrice={totalPrice}
        amountToMint={numToMint}
      />
    </div>
  );
};

export default MintButton;

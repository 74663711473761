import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import { Button, Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: '0 5px',
  },

  button: {
    height: '55px',
    minWidth: '170px !important',
    backgroundColor: `${theme.palette.primary.main} !important`,
    borderRadius: '4px !important',
    color: 'white !important',
    transition: 'all 0.3s linear !important',
    lineHeight: '2.2rem !important',

    '&:disabled': {
      border: '2px solid #212121 !important',
      color: `${theme.palette.primary.main} !important`,
      backgroundColor: 'rgba(0, 0, 0, 0.5) !important',
    },

    '& div': {
      lineHeight: '1.2rem',
      marginTop: '3px',
    },

    '& span': {
      fontSize: '0.85rem !important',
      opacity: 0.7,
    },

    [theme.breakpoints.down('sm')]: {
      width: '260px',
    },
  },
}));

interface BaseButtonProps {
  children: string | JSX.Element;
  disabled?: boolean;
  onClick?: () => any | Promise<any>;
}

const BaseButton: FunctionComponent<BaseButtonProps> = (props) => {
  const { children, disabled, onClick } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Button className={classes.button} disabled={disabled} onClick={onClick}>
        {children}
      </Button>
    </div>
  );
};

export default BaseButton;

import React, { FunctionComponent, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Button, Theme, Typography } from '@mui/material';
import clsx from 'clsx';
import { secsToDays } from '../utils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    gap: '1rem',
    flexDirection: 'column',
    marginTop: '1rem',
  },
  cont: {
    display: 'flex',
    gap: '1rem',
  },
  button: {
    padding: '0.6rem 1.25rem !important',
    paddingBottom: '0.5rem',
    minWidth: '120px !important',
    cursor: 'pointer',
    borderRadius: '4px !important',

    '& p': {
      fontSize: '0.7rem',
      opacity: 0.8,
    },

    '& h6': {
      fontSize: '1rem',
      fontWeight: 600,
      lineHeight: '1.2rem',
    },

    '&:disabled': {
      cursor: 'default',
    },
  },
  selectedBtn: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: 'white !important',
    border: `1px solid ${theme.palette.primary.main}`,
  },
  unSelectedBtn: {
    color: '#737373 !important',
    backgroundColor: '#171717 !important',
    border: '1px solid #262626 !important',
  },
}));

interface IStakingLockTimes {
  lockTimes: number[];
  selectedLockTime: number | null;
  setSelectedLockTime: (lockTime: number) => any;
}

const StakingLockTimes: FunctionComponent<IStakingLockTimes> = (props) => {
  const classes = useStyles();

  const { lockTimes, selectedLockTime, setSelectedLockTime } = props;

  useEffect(() => {
    if (!lockTimes) return;

    setSelectedLockTime(Math.max(...lockTimes));
  }, [lockTimes]);

  function renderLockOptions() {
    if (!lockTimes) return null;

    return lockTimes.map((lockTime, i) => {
      const className =
        lockTime === selectedLockTime
          ? classes.selectedBtn
          : classes.unSelectedBtn;

      return (
        <Button
          key={`locktime-${lockTime}-${i}`}
          className={clsx(classes.button, className)}
          onClick={() => setSelectedLockTime(lockTime)}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}>
            <Typography variant={'h6'}>{secsToDays(lockTime)}</Typography>
            <Typography variant={'body1'}>Days</Typography>
          </div>
        </Button>
      );
    });
  }

  return (
    <div className={classes.root}>
      <div className={classes.cont}>{renderLockOptions()}</div>
    </div>
  );
};

export default StakingLockTimes;

import * as React from 'react';

function SvgIconOpensea(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='18.9 18 55.48 49.93'
      {...props}>
      <path
        d='M22.201 46.512l.194-.305 11.707-18.313a.4.4 0 01.702.05c1.956 4.384 3.643 9.835 2.853 13.228-.338 1.396-1.262 3.287-2.303 5.035a9.212 9.212 0 01-.439.744.398.398 0 01-.333.176H22.543a.4.4 0 01-.342-.615z'
        fill='#d63031'
      />
      <path
        d='M74.38 49.915v2.899a.416.416 0 01-.25.379c-.906.388-4.008 1.812-5.298 3.606-3.292 4.582-5.807 11.133-11.429 11.133H33.95c-8.313 0-15.049-6.76-15.049-15.1v-.268c0-.222.18-.402.402-.402h13.075c.259 0 .448.24.425.495-.092.85.065 1.72.467 2.51a4.6 4.6 0 004.124 2.561h6.473v-5.053h-6.399a.412.412 0 01-.333-.647c.07-.107.148-.218.232-.342.605-.86 1.47-2.197 2.33-3.718.587-1.026 1.156-2.122 1.613-3.222.093-.199.167-.402.24-.601.126-.351.255-.68.347-1.008.093-.277.167-.569.24-.841.218-.934.31-1.924.31-2.95 0-.402-.018-.823-.055-1.225-.018-.44-.074-.879-.13-1.318a13.651 13.651 0 00-.18-1.174 19.795 19.795 0 00-.37-1.757l-.05-.222c-.111-.402-.204-.786-.333-1.188a44.863 44.863 0 00-1.23-3.643c-.162-.458-.347-.897-.532-1.336-.273-.662-.55-1.263-.804-1.831-.13-.26-.24-.495-.352-.736a26.238 26.238 0 00-.383-.804c-.093-.199-.2-.384-.273-.569l-.79-1.46a.258.258 0 01.29-.375l4.948 1.34h.014c.009 0 .013.005.018.005l.652.18.716.204.264.074v-2.94c0-1.42 1.137-2.571 2.543-2.571.703 0 1.34.287 1.798.754.458.467.745 1.105.745 1.817v4.364l.527.148a.42.42 0 01.12.06c.13.097.314.24.55.416.185.148.384.328.624.513.476.384 1.045.879 1.67 1.447.166.144.328.292.476.44a38.114 38.114 0 012.565 2.598c.24.273.477.55.717.841.24.296.495.588.717.879.29.388.605.79.878 1.211.13.199.278.402.402.601.352.532.661 1.082.957 1.632.125.255.255.532.366.805.328.735.587 1.484.753 2.233.051.162.088.337.106.495v.036c.056.222.075.458.093.699a7.464 7.464 0 01-.13 2.307 8.4 8.4 0 01-.272.966c-.111.314-.222.642-.365.952a12.908 12.908 0 01-.995 1.887c-.124.221-.272.457-.42.68-.162.235-.328.457-.476.674-.204.278-.421.569-.643.828a8.787 8.787 0 01-.624.786c-.31.365-.606.712-.916 1.045a9.62 9.62 0 01-.587.638c-.199.222-.402.42-.587.605-.31.31-.569.55-.786.75l-.508.466a.415.415 0 01-.273.102h-3.94v5.053h4.957c1.11 0 2.164-.393 3.014-1.114.292-.254 1.563-1.355 3.066-3.014a.384.384 0 01.19-.116l13.689-3.958a.404.404 0 01.513.389z'
        fill='#d63031'
      />
    </svg>
  );
}

export default SvgIconOpensea;

import { useEffect, useState } from 'react';
import { IToken } from '../components/TokenCard';
import { ethers } from 'ethers';
import TTOOStakingAbi from '../abi/TTOOStaking.json';
import TTOOTokenAbi from '../abi/TTTOToken.json';
import { toast } from 'react-toast';
import { getNFTs } from '../utils';

declare let window: any;

const TTOO_TOKEN_ADDR = '0xF6ee484f82f28d69688f37fe90Af514ce212b7c3'; //'0x8f6897a01cc6b57ee71c6d1a2c81ae8ba81b1447';
const TTOO_STAKING_ADDR = '0x60dA2a1F0693ea2f44F38421F7842a79bD3C9f14'; // '0x8362Ee1565b45525Fc4e05000f4C2f5909B901f4';

export interface IStakedToken {
  id: number;
  unlocksIn: number;
}

interface IBigNumber {
  _hex: string;
  _isBigNumber: boolean;
}

export const useStaking = (currentAccount: string | null | undefined) => {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();

  const stakingContract = new ethers.Contract(
    TTOO_STAKING_ADDR,
    TTOOStakingAbi,
    signer,
  );

  const tokenContract = new ethers.Contract(
    TTOO_TOKEN_ADDR,
    TTOOTokenAbi,
    signer,
  );

  const [unstakedTokens, setUnstakedTokens] = useState<IToken[] | null>(null);
  const [stakedTokens, setStakedTokens] = useState<IStakedToken[] | null>(null);
  const [selectedForStaking, setSelectedForStaking] = useState<IToken[]>([]);
  const [lockTimes, setLockTimes] = useState<number[] | null>(null);
  const [selectedLockTime, setSelectedLockTime] = useState<number | null>(null);
  const [selectedForUnStaking, setSelectedForUnStaking] = useState<
    IStakedToken[]
  >([]);

  const [isPendingStaking, setIsPendingStaking] = useState<boolean>(false);
  const [isPendingUnStaking, setIsPendingUnStaking] = useState<boolean>(false);
  const [isApproving, setIsApproving] = useState<boolean>(false);

  useEffect(() => {
    getUnstakedTokens();
    getStakedTokens();
    getLockTimes();
  }, [currentAccount]);

  async function approveStaking() {
    const isApproved = await tokenContract.isApprovedForAll(
      currentAccount,
      TTOO_STAKING_ADDR,
    );

    if (!isApproved) {
      setIsApproving(true);

      try {
        await tokenContract.setApprovalForAll(TTOO_STAKING_ADDR, true);
      } catch (e) {
        toast.error('Staking was not approved');
      }

      setIsApproving(false);
    }
  }

  async function stakeTokens() {
    if (selectedForStaking.length === 0) return;

    await approveStaking();
    setIsPendingStaking(true);

    try {
      if (selectedForStaking.length === 1) {
        const tokenId = selectedForStaking[0].id;
        const stakeTrx = await stakingContract.stake(tokenId, selectedLockTime);
        const receipt = await provider.waitForTransaction(stakeTrx.hash, 1);

        if (Boolean(receipt)) await updateAfterStaking();
        return;
      }

      const tokenIds = selectedForStaking.map(({ id }) => [
        id,
        selectedLockTime,
      ]);

      const multiStakeTrx = await stakingContract.stakeMultiple(tokenIds);
      const receipt = await provider.waitForTransaction(multiStakeTrx.hash, 1);
      if (Boolean(receipt)) await updateAfterStaking();
    } catch (e: any) {
      setIsPendingStaking(false);
      toast.error(e.message ?? 'Unable to stake');
    }
  }

  async function updateAfterStaking() {
    const updatedUnstaked = unstakedTokens?.filter(
      ({ id }) => !selectedForStaking.find((token) => id === token.id),
    );

    setSelectedForStaking([]);
    setUnstakedTokens(updatedUnstaked as IToken[]);

    await getStakedTokens();
    setIsPendingStaking(false);
  }

  async function getLockTimes() {
    const _lockTimes: IBigNumber[] = await stakingContract.getLockTimes();
    const sortedLockTimes = _lockTimes
      .map((lockTime) => parseInt(lockTime._hex))
      .sort((a, b) => a - b);

    setLockTimes(sortedLockTimes);
  }

  async function getUnstakedTokens() {
    if (!currentAccount) {
      return setUnstakedTokens(null);
    }

    const tokens: IToken[] | any = await getNFTs(
      '0x1',
      TTOO_TOKEN_ADDR,
      currentAccount,
    );

    setUnstakedTokens(tokens);
  }

  async function getStakedTokens() {
    if (!currentAccount) {
      return setStakedTokens(null);
    }

    const userStakingInfo = await stakingContract.getUserStakingInfo(
      currentAccount,
    );

    const tokens: IBigNumber[] = userStakingInfo[0];
    const unlocksIn: IBigNumber[] = userStakingInfo[1];

    const tokensWithUnlocksIn: IStakedToken[] = [];

    tokens.forEach((tokens: IBigNumber, i: number) => {
      tokensWithUnlocksIn.push({
        id: parseInt(tokens._hex),
        unlocksIn: parseInt(unlocksIn[i]._hex) - new Date().getTime() / 1000,
      });
    });

    const sortedTokens = tokensWithUnlocksIn.sort((a, b) => {
      if (a.unlocksIn <= 0 && b.unlocksIn <= 0) {
        return a.id - b.id;
      }

      return a.unlocksIn - b.unlocksIn;
    });

    setStakedTokens(sortedTokens);
  }

  function selectForStaking(token: IToken, isSelected: boolean) {
    if (isSelected) {
      return setSelectedForStaking(
        selectedForStaking.filter(({ id }) => id !== token.id),
      );
    }

    setSelectedForStaking([...selectedForStaking, token]);
  }

  function selectForUnStaking(token: IStakedToken, isSelected: boolean) {
    if (isSelected) {
      return setSelectedForUnStaking(
        selectedForUnStaking.filter(({ id }) => id !== token.id),
      );
    }

    setSelectedForUnStaking([...selectedForUnStaking, token]);
  }

  async function unStakeTokens() {
    if (selectedForUnStaking.length === 0) return;
    setIsPendingUnStaking(true);

    try {
      if (selectedForUnStaking.length === 1) {
        const tokenId = selectedForUnStaking[0].id;
        const unStakeTrx = await stakingContract.unstake(tokenId);
        const receipt = await provider.waitForTransaction(unStakeTrx.hash, 1);

        if (Boolean(receipt)) await updateAfterUnStaking();
        return;
      }

      const tokenIds = selectedForUnStaking.map(({ id }) => id);

      const multiUnStakeTrx = await stakingContract.unstakeMultiple(tokenIds);
      const receipt = await provider.waitForTransaction(
        multiUnStakeTrx.hash,
        1,
      );
      if (Boolean(receipt)) await updateAfterUnStaking();
    } catch (e: any) {
      setIsPendingUnStaking(false);
      toast.error(e.message ?? 'Unable to un-stake');
    }
  }

  async function updateAfterUnStaking() {
    const updatedStaked = stakedTokens?.filter(
      ({ id }) => !selectedForUnStaking.find((token) => id === token.id),
    );

    const updatedUnStaked = selectedForUnStaking.map(({ id }) => ({ id }));
    setUnstakedTokens(
      [...(unstakedTokens as IToken[]), ...(updatedUnStaked as IToken[])].sort(
        (a: IToken, b: IToken) => a.id - b.id,
      ),
    );

    setSelectedForUnStaking([]);
    setStakedTokens(updatedStaked as IStakedToken[]);
    setIsPendingUnStaking(false);
  }

  return {
    currentAccount,
    stakedTokens,
    unstakedTokens,
    selectedForStaking,
    selectForStaking,
    lockTimes,
    selectedLockTime,
    setSelectedLockTime,
    stakeTokens,
    isPendingStaking,
    isPendingUnStaking,
    isApproving,
    selectedForUnStaking,
    selectForUnStaking,
    unStakeTokens,
  };
};

import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import { Theme, Typography } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: '80px',
    width: '100%',
    backgroundColor: '#111010',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  container: {
    width: '100%',
    maxWidth: '1200px',
    borderTop: '1px solid #212121',
    display: 'flex',
    padding: '50px 0',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: '#111010',

    '& p': {
      color: '#57606f !important',
      opacity: '0.7',
    },
  },
  img: {
    marginBottom: '-20px',
  },
  siteBy: {
    marginTop: '10px !important',
    fontSize: '14px !important',

    '& a': {
      textDecoration: 'none',
      fontWeight: 600,
      color: '#d63031',
    },
  },
}));

interface FooterProps {}

const Footer: FunctionComponent<FooterProps> = (props) => {
  const classes = useStyles();

  return (
    <footer className={classes.root}>
      <div className={classes.container}>
        <Typography variant={'body1'}>
          © {new Date().getFullYear()} This Thing of Ours
        </Typography>
        <Typography variant={'body1'} className={classes.siteBy}>
          Site by{' '}
          <a href={'https://github.com/sanderhelleso'} target={'_blank'}>
            0xNxt.eth
          </a>
        </Typography>
      </div>
    </footer>
  );
};

export default Footer;
